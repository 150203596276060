import { AnimationEvent, PureComponent } from 'react';

import { BIG_PLACEHOLDER_CONFIG } from 'Component/ProductConfigurableAttributes/ProductConfigurableAttributes.config';
import { ReactElement } from 'Type/Common.type';
import { GQLProductStockStatus } from 'Type/Graphql.type';
import { noopFn } from 'Util/Common';
import { getBooleanLabel } from 'Util/Product';
import { IndexedAttributeWithValueOption } from 'Util/Product/Product.type';

import ProductConfigurableAttributes from './ProductConfigurableAttributes.component';
import {
    ProductConfigurableAttribute,
    ProductConfigurableAttributesComponentContainerFunctions,
    ProductConfigurableAttributesComponentContainerPropsKeys,
    ProductConfigurableAttributesComponentProps,
    ProductConfigurableAttributesContainerProps,
} from './ProductConfigurableAttributes.type';

/** @namespace PlugAndSell2/Component/ProductConfigurableAttributes/Container */
export class ProductConfigurableAttributesContainer<
    P extends ProductConfigurableAttributesContainerProps = ProductConfigurableAttributesContainerProps
> extends PureComponent<P> {
    static defaultProps: Partial<ProductConfigurableAttributesContainerProps> = {
        getLink: noopFn as unknown as (filterKey: string, value: string) => string,
        isExpandable: true,
        showProductAttributeAsLink: true,
        variants: [],
        isReady: true,
        mix: {},
        numberOfPlaceholders: BIG_PLACEHOLDER_CONFIG,
        inStock: true,
        updateAddToCartTriggeredWithError: noopFn,
        addToCartTriggeredWithError: false,
        isContentExpanded: false,
    };

    containerFunctions: ProductConfigurableAttributesComponentContainerFunctions = {
        handleOptionClick: this.handleOptionClick.bind(this),
        getSubHeading: this.getSubHeading.bind(this),
        isSelected: this.isSelected.bind(this),
        getLink: this.getLink.bind(this),
        getIsConfigurableAttributeAvailable: this.getIsConfigurableAttributeAvailable.bind(this),
        handleShakeAnimationEnd: this.handleShakeAnimationEnd.bind(this),
    };

    containerProps(): Pick<ProductConfigurableAttributesComponentProps, ProductConfigurableAttributesComponentContainerPropsKeys> {
        const {
            isExpandable,
            isReady,
            mix,
            numberOfPlaceholders,
            parameters,
            cutToSizeEnabled,
            onCutToSizeClick,
            showProductAttributeAsLink,
            updateConfigurableVariant,
            inStock,
            addToCartTriggeredWithError,
            updateAddToCartTriggeredWithError,
            isContentExpanded,
            isCutToSize,
        } = this.props;

        return {
            configurable_options: this.preparedConfigurableOptions(),
            isExpandable,
            isReady,
            mix,
            numberOfPlaceholders,
            parameters,
            showProductAttributeAsLink,
            updateConfigurableVariant,
            cutToSizeEnabled,
            onCutToSizeClick,
            inStock,
            addToCartTriggeredWithError,
            updateAddToCartTriggeredWithError,
            isContentExpanded,
            isCutToSize,
        };
    }

    getLink({ attribute_code = '', attribute_value = '' }: Partial<ProductConfigurableAttribute>): string {
        const { getLink } = this.props;

        return getLink(attribute_code, attribute_value);
    }

    getSubHeading({
        attribute_values = [],
        attribute_code,
        attribute_options = {},
        is_boolean = false,
    }: Partial<ProductConfigurableAttribute>): string {
        return attribute_values
            .reduce(
                (acc: string[], attribute_value) =>
                    this.isSelected({
                        attribute_code,
                        attribute_value,
                    })
                        ? [...acc, getBooleanLabel(attribute_options[attribute_value].label, is_boolean)]
                        : acc,
                []
            )
            .join(', ');
    }

    handleOptionClick({ attribute_code = '', attribute_value = '', frontend_input = '' }: Partial<ProductConfigurableAttribute>): void {
        const { updateConfigurableVariant } = this.props;

        if (updateConfigurableVariant) {
            updateConfigurableVariant(attribute_code, attribute_value, frontend_input);
        }
    }

    isSelected({ attribute_code = '', attribute_value = '' }: Partial<ProductConfigurableAttribute>): boolean {
        const { parameters = {} as Record<string, string> } = this.props;
        const parameter = parameters[attribute_code];

        if (parameter === undefined) {
            return false;
        }

        if (parameter.length !== undefined) {
            return parameter.includes(attribute_value);
        }

        return parameter === attribute_value;
    }

    handleShakeAnimationEnd(e: AnimationEvent<HTMLElement>): void {
        e.preventDefault();
        const { updateAddToCartTriggeredWithError } = this.props;

        (e.target as HTMLElement)?.classList?.remove('[class*=_isUnselected]');

        updateAddToCartTriggeredWithError();
    }

    getIsConfigurableAttributeAvailable({ attribute_code = '', attribute_value = '' }: Partial<ProductConfigurableAttribute>): boolean {
        const { parameters, variants } = this.props;

        // skip out of stock check, if variants data has not been provided
        if (!variants.length) {
            return true;
        }

        const isAttributeSelected = Object.hasOwnProperty.call(parameters, attribute_code);

        // If value matches current attribute_value, option should be enabled
        if (isAttributeSelected && parameters[attribute_code] === attribute_value) {
            return true;
        }

        const parameterPairs = Object.entries(parameters);

        const selectedAttributes = isAttributeSelected ? parameterPairs.filter(([key]) => key !== attribute_code) : parameterPairs;

        return variants.some(({ stock_status, attributes }) => {
            const { attribute_value: foundValue } = attributes[attribute_code] || {};

            return (
                stock_status === GQLProductStockStatus.IN_STOCK &&
                // Variant must have currently checked attribute_code and attribute_value
                foundValue === attribute_value &&
                // Variant must have all currently selected attributes
                selectedAttributes.every(([key, value]) => attributes[key].attribute_value === value)
            );
        });
    }

    // filter our options with value of CUSTOM
    preparedConfigurableOptions(): Record<string, Partial<ProductConfigurableAttribute>> {
        const { configurable_options } = this.props;

        const preparedConfigurableOptions: Record<string, Partial<ProductConfigurableAttribute>> = {};

        Object.entries(configurable_options).forEach(([key, value]) => {
            if (!value?.attribute_options) {
                return;
            }

            preparedConfigurableOptions[key] = value;

            const preparedAttributeOptions: Record<string, IndexedAttributeWithValueOption> = {};
            Object.entries(value.attribute_options).forEach(([key2, value2]) => {
                if (value2.label === 'CUSTOM') {
                    return;
                }

                preparedAttributeOptions[key2] = value2;
            });
            preparedConfigurableOptions[key].attribute_options = preparedAttributeOptions;
        });

        return preparedConfigurableOptions;
    }

    render(): ReactElement {
        return <ProductConfigurableAttributes {...this.containerProps()} {...this.containerFunctions} />;
    }
}

export default ProductConfigurableAttributesContainer;
