import { PureComponent } from 'react';

import Button from 'Component/Button';
import { FieldType } from 'Component/Field/Field.config';
import { ReactElement } from 'Type/Common.type';
import { noopFn } from 'Util/Common';

import Field from '../Field';
import { FieldVariant } from '../Field/Field.type';
import { ProductConfigurableAttributeDropdownComponentProps } from './ProductConfigurableAttributeDropdown.type';

import './ProductConfigurableAttributeDropdown.style';

/** @namespace PlugAndSell2/Component/ProductConfigurableAttributeDropdown/Component */
export class ProductConfigurableAttributeDropdownComponent extends PureComponent<ProductConfigurableAttributeDropdownComponentProps> {
    static defaultProps: Partial<ProductConfigurableAttributeDropdownComponentProps> = {
        selectValue: '',
        selectLabel: 'attribute',
        handleShakeAnimationEnd: noopFn,
        isUnselected: false,
    };

    renderCutToSize(): ReactElement {
        const { onCutToSizeClick, selectEnabled } = this.props;

        const buttonEnabled = !selectEnabled;

        return (
            <>
                <span block="ProductConfigurableAttributeDropdown" elem="CutToSizeSeparator">
                    {__('or')}
                </span>
                <Button
                    block="ProductConfigurableAttributeDropdown"
                    elem="CutToSizeButton"
                    // eslint-disable-next-line @typescript-eslint/naming-convention
                    mods={{ active: buttonEnabled, variant_outline: !buttonEnabled }}
                    onClick={onCutToSizeClick}
                >
                    {__('Cut to size')}
                </Button>
            </>
        );
    }

    render(): ReactElement {
        const { selectEnabled, selectOptions, selectValue, selectName, selectLabel, onChange, handleShakeAnimationEnd, isCutToSize, isUnselected } =
            this.props;

        return (
            <div block="ProductConfigurableAttributeDropdown" elem="Wrapper">
                <Field
                    type={FieldType.SELECT}
                    variant={FieldVariant.TERTIARY}
                    mix={{ block: 'ProductConfigurableAttributeDropdown', mods: { isUnselected } }}
                    attr={{
                        id: selectName,
                        name: selectName,
                        title: 'test',
                        defaultValue: selectValue,
                        selectPlaceholder: __('Choose %s...', selectLabel.toLowerCase()),
                        onAnimationEnd: handleShakeAnimationEnd,
                        noPlaceholder: isCutToSize,
                    }}
                    events={{
                        onLoad: (e) => {
                            onChange(String(e));
                        },
                        onChange,
                    }}
                    options={selectOptions}
                    isDisabled={!selectEnabled}
                />
                {isCutToSize && this.renderCutToSize()}
            </div>
        );
    }
}

export default ProductConfigurableAttributeDropdownComponent;
